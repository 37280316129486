<template>
    <div class="main_box">
        <div class="record_title">
            <img class="record_back" src="img/backw.png" alt="" @click="closePage">
            <span>我的关系网</span>
            <span class="rank_item" @click="toRankBoard">关系榜</span>
        </div>
        <div class="btn_box">
            <div :style="curIndex == index ? ('background-color:' + item.bc + ';' + 'color:' + color) : ''"
                v-for="(item, index) in tabs" :key="index" @click="clickTab(index)">{{ item.name }}</div>
        </div>
        <div class="net_box" v-if="curIndex == 3" style="background-image: url('img/relationNet/net_bg_0.png');">
            <div class="noData margin-top-xl" v-if="list.length == 0">
                <img src="img/noData.png" alt="">
            </div>
            <div class="top_box" v-if="list.length>0">
                <div>
                    <img class="face" :src="list[0].a_head_portrait" alt="">
                    <div class="nickname">{{ list[0].a_nickname }}</div>
                </div>
                <div class="rank_bg_box">
                    <img class="rank_bg" :src="'img/relationNet/' + curIndex + '_' + list[0].network_rank.rank + '.png'"
                        alt="">
                </div>
                <div>
                    <img class="face" :src="list[0].b_head_portrait" alt="">
                    <div class="nickname">{{ list[0].b_nickname }}</div>
                </div>
            </div>
            <div class="bottom_box" v-if="list.length>0">
                <div style="display: flex;justify-content: center;">
                    <img class="rank_bg" :src="'img/relationNet/' + curIndex + '_' + item.network_rank.rank + '.png'"
                        alt="">
                    <div class="value">亲密度:{{ item.network_rank.exp }}</div>
                    <img class="rank_bg" :src="'img/relationNet/' + curIndex + '_' + item.network_rank.next_rank + '.png'"
                        alt="">
                </div>
                <div class="progress-box" style="width:250px;">
                    <div class="my-progress" :style="'width:' + item.network_rank.ratio * 250 + 'px'"></div>
                </div>
                <div class="diff_value">距下一级&nbsp;&nbsp;&nbsp;&nbsp;{{ item.network_rank.differ_exp }}</div>
            </div>
        </div>


        <!-- 关系数组大于1 -->
        <div class="net_box" v-if="curIndex == 1 && !this.showChildren"
            style="background-image: url('img/relationNet/net_bg_1.png');">
            <div class="item_box">
                <div class="noData margin-top-xl" v-if="list.length == 0">
                    <img src="img/noData.png" alt="">
                </div>
                <div class="item" v-for="(item, index) in list" :key="index" @click="showDeatail(item)">
                    <img class="face" :src="item.b_head_portrait" alt="">
                    <img class="rank_bg" :src="'img/relationNet/' + curIndex + '_' + item.network_rank.rank + '.png'"
                        alt="">
                    <div class="nickname">{{ item.b_nickname }}</div>
                </div>
            </div>
        </div>
        <div class="net_box" v-if="curIndex == 2 && !this.showChildren"
            style="background-image: url('img/relationNet/net_bg_2.png');">
            <div class="item_box">
                <div class="noData margin-top-xl" v-if="list.length == 0">
                    <img src="img/noData.png" alt="">
                </div>
                <div class="item" v-for="(item, index) in list" :key="index" @click="showDeatail(item)">
                    <img class="face" :src="item.b_head_portrait" alt="">
                    <img class="rank_bg" :src="'img/relationNet/' + curIndex + '_' + item.network_rank.rank + '.png'"
                        alt="">
                    <div class="nickname">{{ item.b_nickname }}</div>
                </div>
            </div>
        </div>
        <div class="net_box" v-if="curIndex == 0 && !this.showChildren"
            style="background-image: url('img/relationNet/net_bg_3.png');">
            <div class="item_box">
                <div class="noData margin-top-xl" v-if="list.length == 0">
                    <img src="img/noData.png" alt="">
                </div>
                <div class="item" v-for="(item, index) in list" :key="index" @click="showDeatail(item)">
                    <img class="face" :src="item.b_head_portrait" alt="">
                    <img class="rank_bg" :src="'img/relationNet/' + curIndex + '_' + item.network_rank.rank + '.png'"
                        alt="">
                    <div class="nickname">{{ item.b_nickname }}</div>
                </div>
            </div>
        </div>


        <!-- 关系数组等于1或当前为详情展示 -->
        <div class="net_box" v-if="curIndex == 1 && this.showChildren"
            style="background-image: url('img/relationNet/net_bg_1_.png');">
            <div v-if="list.length>1" class="close_btn" @click="showChildren = false">X</div>
            <div class="top_box">
                <div>
                    <img class="face" :src="item.a_head_portrait" alt="">
                    <div class="nickname">{{ item.a_nickname }}</div>
                </div>
                <div class="rank_bg_box">
                    <img class="rank_bg" :src="'img/relationNet/' + curIndex + '_' + item.network_rank.rank + '.png'"
                        alt="">
                </div>
                <div>
                    <img class="face" :src="item.b_head_portrait" alt="">
                    <div class="nickname">{{ item.b_nickname }}</div>
                </div>
            </div>
            <div class="bottom_box">
                <div style="display: flex;justify-content: center;">
                    <img class="rank_bg" :src="'img/relationNet/' + curIndex + '_' + item.network_rank.rank + '.png'"
                        alt="">
                    <div class="value">亲密度:{{ item.network_rank.exp }}</div>
                    <img class="rank_bg" :src="'img/relationNet/' + curIndex + '_' + item.network_rank.next_rank + '.png'"
                        alt="">
                </div>
                <div class="progress-box" style="width:250px;">
                    <div class="my-progress" :style="'width:' + item.network_rank.ratio * 250 + 'px'"></div>
                </div>
                <div class="diff_value">距下一级&nbsp;&nbsp;&nbsp;&nbsp;{{ item.network_rank.differ_exp }}</div>
            </div>
        </div>
        <div class="net_box" v-if="curIndex == 2 && this.showChildren"
            style="background-image: url('img/relationNet/net_bg_2_.png');">
            <div v-if="list.length>1" class="close_btn" @click="showChildren = false">X</div>
            <div class="top_box">
                <div>
                    <img class="face" :src="item.a_head_portrait" alt="">
                    <div class="nickname">{{ item.a_nickname }}</div>
                </div>
                <div class="rank_bg_box">
                    <img class="rank_bg" :src="'img/relationNet/' + curIndex + '_' + item.network_rank.rank + '.png'"
                        alt="">
                </div>
                <div>
                    <img class="face" :src="item.b_head_portrait" alt="">
                    <div class="nickname">{{ item.b_nickname }}</div>
                </div>
            </div>
            <div class="bottom_box">
                <div style="display: flex;justify-content: center;">
                    <img class="rank_bg" :src="'img/relationNet/' + curIndex + '_' + item.network_rank.rank + '.png'"
                        alt="">
                    <div class="value">亲密度:{{ item.network_rank.exp }}</div>
                    <img class="rank_bg" :src="'img/relationNet/' + curIndex + '_' + item.network_rank.next_rank + '.png'"
                        alt="">
                </div>
                <div class="progress-box" style="width:250px;">
                    <div class="my-progress" :style="'width:' + item.network_rank.ratio * 250 + 'px'"></div>
                </div>
                <div class="diff_value">距下一级&nbsp;&nbsp;&nbsp;&nbsp;{{ item.network_rank.differ_exp }}</div>
            </div>
        </div>
        <div class="net_box" v-if="curIndex == 0 && this.showChildren"
            style="background-image: url('img/relationNet/net_bg_3_.png');">
            <div v-if="list.length>1" class="close_btn" @click="showChildren = false">X</div>
            <div class="top_box">
                <div>
                    <img class="face" :src="item.a_head_portrait" alt="">
                    <div class="nickname">{{ item.a_nickname }}</div>
                </div>
                <div class="rank_bg_box">
                    <img class="rank_bg" :src="'img/relationNet/' + curIndex + '_' + item.network_rank.rank + '.png'"
                        alt="">
                </div>
                <div>
                    <img class="face" :src="item.b_head_portrait" alt="">
                    <div class="nickname">{{ item.b_nickname }}</div>
                </div>
            </div>
            <div class="bottom_box">
                <div style="display: flex;justify-content: center;">
                    <img class="rank_bg" :src="'img/relationNet/' + curIndex + '_' + item.network_rank.rank + '.png'"
                        alt="">
                    <div class="value">亲密度:{{ item.network_rank.exp }}</div>
                    <img class="rank_bg" :src="'img/relationNet/' + curIndex + '_' + item.network_rank.next_rank + '.png'"
                        alt="">
                </div>
                <div class="progress-box" style="width:250px;">
                    <div class="my-progress" :style="'width:' + item.network_rank.ratio * 250 + 'px'"></div>
                </div>
                <div class="diff_value">距下一级&nbsp;&nbsp;&nbsp;&nbsp;{{ item.network_rank.differ_exp }}</div>
            </div>
        </div>

    </div>
</template>
<script>
import "@/assets/css/relationNet.css"
import { myNet } from "@/api/relationNet"
export default {
    data() {
        return {
            tabs: [{ name: '玩伴', index: 0, bc: '#70e2a9' }, { name: '兄弟', index: 1, bc: '#6cc4e6' }, { name: '闺蜜', index: 2, bc: '#debbaf' }, { name: '恋人', index: 3, bc: '#cb9afe' }],
            color: '#111111',
            curIndex: 0,
            list: [],
            item: {},
            showChildren: false
        }
    },
    created() {
        let access_token = this.$route.query.access_token
        localStorage.setItem('access_token', access_token)
        this.getNet()
    },
    methods: {
        clickTab(i) {
            this.curIndex = i
            this.getNet()
        },
        getNet() {
            myNet({ type: this.curIndex + 1 }).then(res => {
                this.list = res.data
                if (this.list.length == 1) {
                    this.showChildren = true
                    this.item = this.list[0]
                } else {
                    this.showChildren = false
                }
            })
        },
        showDeatail(item) {
            console.log(item)
            this.item = item
            this.showChildren = true
        },
        toRankBoard() {
            this.$router.push({ path: '/relationNetRank' })
        },
        closePage() {
            console.log('执行了')
            let params = '调用'
            this.$bridge.callhandler('closePage', params, (data) => {
                // 处理返回数据
            })
        },
    }
}
</script>